<template>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px" style="width:800px" class="el-form-item">
        <el-row>
            <el-col :span="24">
                <el-form-item label="种质编号:" prop="identifier" >
                    <el-input v-model="form.identifier" placeholder="请输入种质编号" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="作(植)物名称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入作(植)物名称" />
                </el-form-item>
            </el-col>
            <el-col :span="10">
                <el-form-item label="种质名称" prop="germplasmName">
                    <el-input v-model="form.germplasmName" placeholder="请输入种质名称" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <el-form-item label="作(植)物类别:" style="width:100%">
                    <el-input v-model="form.type" placeholder="请输入作(植)物类别" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="主要特征" prop="feature">
                    <el-input v-model="form.feature" placeholder="请输入主要特征" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="主要用途" prop="purpose">
                    <el-input v-model="form.purpose" placeholder="请输入主要用途" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="保存设施" prop="saveFacilities">
                    <el-input v-model="form.saveFacilities" placeholder="请输入保存设施" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="项目归口" prop="projectAlley">
                    <el-input v-model="form.projectAlley" placeholder="请输入项目归口" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="种质类型">
                    <el-input v-model="form.germplasmType" placeholder="请选择种质类型" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="保存数量" prop="num">
                    <el-input v-model="form.num" placeholder="请输入保存数量" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="计量单位" prop="measurementUnit">
                    <el-input v-model="form.measurementUnit" placeholder="请输入计量单位" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="保存单位" prop="saveUnit">
                    <el-input v-model="form.saveUnit" placeholder="请输入保存单位" />
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="12">
                <el-form-item label="保存库" prop="saveDataset">
                    <el-input v-model="form.saveDataset" placeholder="请输入保存库" />
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="保存地点" prop="savePlace">
                    <el-input v-model="form.savePlace" placeholder="请输入保存地点" />
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <el-form-item label="入库年份" prop="year">
                    <el-input v-model="form.year" placeholder="请输入入库年份" />
                </el-form-item>
            </el-col>
        <el-col :span="12">
            <el-form-item label="保存性质" prop="saveNature">
                <el-input v-model="form.saveNature" placeholder="请输入保存性质" />
            </el-form-item>
        </el-col>
        </el-row>
        <el-row>
            <el-col>
                <el-form-item label="是否共享" prop="share">
                    <el-select v-model="form.share" placeholder="请选择是否共享">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>

        <el-row>
            <el-col>
                <el-form-item label="资源描述" prop="describe·">
                    <el-input v-model="form.describe" placeholder="请输入资源描述" type="textarea"/>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row>
            <el-form-item label="种质图片" prop="img">
                <el-upload :action="url"
                           :before-upload="beforeAvatarUpload"
                           :on-success="getImgUrl"
                           list-type="picture"
                           :limit="1"
                           :file-list="fileList">
                    <el-button size="small" type="primary" v-if="update">更换图片</el-button>
                    <el-button size="small" type="primary" v-else>点击上传</el-button>
                </el-upload>
            </el-form-item>
        </el-row>
        <el-row>
            <el-button type="primary" @click="submitForm">确 定</el-button>
            <el-button @click="reset" v-if="!update">重 置</el-button>
            <el-button @click="cancel" v-if="update">取 消</el-button>
        </el-row>

    </el-form>
</template>

<script>

    import config from '../../../config/index'
    export default {
        name: "resources-data-save",
        data(){
            return{
                labelPosition: 'top',
                fileList:[],
                // 表单参数
                form: {
                    id: undefined,
                    identifier: undefined,
                    type: undefined,
                    name: undefined,
                    germplasmName: undefined,
                    feature: undefined,
                    purpose: undefined,
                    describe: undefined,
                    saveFacilities: undefined,
                    germplasmType: undefined,
                    num: undefined,
                    measurementUnit: undefined,
                    saveUnit: undefined,
                    saveDataset: undefined,
                    savePlace: undefined,
                    year: undefined,
                    saveNature: undefined,
                    share: undefined,
                    shareMode: undefined,
                    projectAlley: undefined
                },
                // 表单校验
                rules: {

                },
                options: [{
                    value: '0',
                    label: '不共享'
                }, {
                    value: '1',
                    label: '共享'
                }],
                url: config.api + '/open-api/file/admin/upload'
            }
        },
        props:{
          id:{
              type: Number,
              default: null
          },
            update:{
              type: Boolean,
                default: false
            }
        },

        methods:{
            /** 提交按钮 */
            submitForm: function() {
                this.loading = true;
                this.$refs["form"].validate(valid => {
                    if (valid) {
                        if (this.update && this.id){
                            this.$http({
                                url: this.$http.adornUrl('/resources'),
                                method: 'put',
                                data: this.form
                            }).then(response => {
                                this.loading = false;
                                this.$emit("updateSuccess");
                                this.$message.success("资源修改成功")
                            });
                        }else{
                            this.$http({
                                url: this.$http.adornUrl('/resources'),
                                method: 'post',
                                data: this.form
                            }).then(response => {
                                this.loading = false;
                                this.$message.success("资源增加成功")
                            });
                        }
                    }
                });
            },
            reset() {
                this.form= {
                    identifier: undefined,
                        type: undefined,
                        name: undefined,
                        germplasmName: undefined,
                        feature: undefined,
                        purpose: undefined,
                        describe: undefined,
                        saveFacilities: undefined,
                        germplasmType: undefined,
                        num: undefined,
                        measurementUnit: undefined,
                        saveUnit: undefined,
                        saveDataset: undefined,
                        savePlace: undefined,
                        year: undefined,
                        saveNature: undefined,
                        share: undefined,
                        shareMode: undefined,
                        projectAlley: undefined
                }
            },
            cancel(){
                this.$emit("updateSuccess");
            },
            init(){
                if (this.update && this.id){
                    this.reset();
                    this.loading = true;
                    this.$http({
                        url: this.$http.adornUrl('/resources/'+this.id),
                        method: 'get',
                    }).then(response=>{
                        this.form = response.data.data;
                        if(this.form.img){
                            this.fileList = [{
                                name: this.form.img.split('\\')[0],
                                url: this.form.img
                            }]
                        }
                        this.loading = false;
                    })
                }
            },
            beforeAvatarUpload(file) {
                // this.fileList = []
                // if (this.fileList.length){
                //     this.$message.error('只能上传一张图片！');
                //     return false;
                // }
                const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
                if (!isJPG) {
                    this.$message.error('上传图片只能是jpg/png格式!');
                }
                return isJPG;
            },
            getImgUrl(response, file, fileList) {
                this.urlfileList = [file]
                this.form.img = response.url
            }
        }
    }
</script>

<style scoped>
    .el-form-item{
        margin-bottom: 16px;
    }
</style>
